.partners {
   width: 100%;
   padding: 15px 15px 0 15px;
   &__body {
      padding: 10px 10px 10px 20px;
      border-radius: 25px;
      background: var(--black-s);
      border: 1px solid var(--border);
      display: flex;
      position: relative;
   }
   &__title {
      font-size: 20px;
      font-weight: 700;
      text-transform: uppercase;
      text-wrap: none;
      color: var(--white-p);
      padding-right: 10px;
   }
   &__partners {
      flex: 1 0 auto;
      mask-image: linear-gradient(
         to left,
         rgba(0, 0, 0, 0),
         #000,
         #000,
         rgba(0, 0, 0, 0)
      );
      position: relative;
      overflow: hidden;
      margin-right: 50px;
   }
   &__partner {
      font-size: 20px;
      font-weight: 700;
      text-transform: uppercase;
      position: absolute;
      white-space: nowrap;
      animation: scroll-left 30s ease-in-out infinite;
      color: var(--white-p);
      i {
         text-transform: inherit;
      }
      transition: 1s;
   }
   &__body:hover &__partner {
      transform: translate(50px);
   }
   &__link {
      position: absolute;
      top: 10px;
      right: 10px;
      color: var(--black-s);
      background: var(--white-p);
      border-radius: 50px;
      width: 60px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px;
   }
   &__svg {
      div {
         display: flex;
         justify-content: center;
         align-items: center;
         svg {
            height: 14px;
            width: 14px;
            transition: 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
         }
      }
   }
   &__body:hover &__svg svg {
      transform: rotate(45deg);
   }
}
@media (max-width: $mobile) {
   .partners {
      &__body {
         display: flex;
         flex-direction: column;
         height: 85px;
         gap: 10px;
         padding: 10px;
      }
      &__title {
         padding-left: 5px;
      }
      &__partners {
         margin-right: 0px;
      }
   }
}
@keyframes scroll-left {
   0% {
      left: 0%;
      width: 100%;
   }
   50% {
      left: -100%;
      width: 100%;
   }
   100% {
      left: 0%;
      width: 100%;
   }
}
