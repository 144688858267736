.cart-products {
   &__grid {
      display: grid;
      grid-template-rows: repeat(1fr);
      grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
      gap: 15px;
   }
   &__more {
      aspect-ratio: 2/1;
      border-radius: 25px;
      background: var(--black-s);
      border: 1px solid var(--border);
      color: var(--border);
      padding: 20px;
      font-size: 70px;
      font-weight: 100;
      display: flex;
      justify-content: center;
      align-items: center;
      user-select: none;
      transition: 0.3s;
      cursor: pointer;
      line-height: 0;
   }
   &__more-1 {
      aspect-ratio: 3/1;
      position: relative;
   }
   &__more-2 {
      position: relative;
   }
   &__more:hover {
      font-size: 80px;
      color: var(--white-p);
   }
   &__more-1::after,
   &__more-2::after {
      position: absolute;
      font-size: 14px;
      bottom: 30px;
      left: 50%;
      transform: translatex(-50%);
      color: var(--border);
      transition: 0.3s;
      white-space: nowrap;
      font-weight: 300;
   }
   &__more-1::after {
      content: 'No product is currently selected';
   }
   &__more-2::after {
      content: 'Click to select more';
   }
   &__more-1:hover::after,
   &__more-2:hover::after {
      color: var(--white-p);
   }
}
.cart-products-item {
   display: flex;
   aspect-ratio: 2/1;
   gap: 10px;
   padding: 10px;
   border-radius: 25px;
   background: var(--black-s);
   border: 1px solid var(--border);
   transition: 0.3s;
   opacity: 1;
   &__img-wrapper {
      position: relative;
      height: 100%;
      aspect-ratio: 1 /1;
      cursor: pointer;
   }
   .lazy-load-image-loaded {
      height: 100%;
      width: 100%;
   }
   &__img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 15px;
      border: 1px solid var(--border);
      z-index: 300;
   }
   &__size,
   &__color,
   &__open-icon,
   &__trash-icon {
      color: #050505;
      background: #fff;
      border-radius: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px;
      z-index: 6;
   }
   &__open-icon {
      width: 40px;
      height: 20px;
      position: absolute;
      left: 10px;
      top: 10px;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 10px -2px,
         rgba(0, 0, 0, 0.3) 0px 9px 18px -9px;
      div svg {
         height: 8px;
         width: 8px;
      }
   }
   &__color,
   &__size {
      width: 40px;
      height: 20px;
      position: absolute;
      left: 10px;
      bottom: 10px;
      font-size: 10px;
      font-weight: 600;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 10px -2px,
         rgba(0, 0, 0, 0.3) 0px 9px 18px -9px;
   }
   &__color {
      bottom: 35px;
   }
   &__trash-icon {
      width: 60px;
      height: 30px;
      cursor: pointer;
      div svg {
         height: 14px;
         width: 14px;
      }
   }
   &__svg {
      div {
         display: flex;
         justify-content: center;
         align-items: center;
         svg {
            transition: 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
         }
      }
   }
   &:hover &__open-icon &__svg svg {
      transform: rotate(45deg);
   }
   &__trash-icon {
      color: var(--black-s);
      background: var(--white-p);
      div svg {
         height: 14px;
         width: 14px;
         transition: 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
      }
   }
   &__trash-icon:hover {
      background: rgb(255, 43, 43);
   }
   &__info {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      padding: 10px;
      aspect-ratio: 1.04/1;
   }
   &__name {
      white-space: nowrap;
      mask-image: linear-gradient(to left, rgba(0, 0, 0, 0.5), #000);
      overflow: hidden;
      max-width: 100%;
   }
   &__row {
      display: flex;
      justify-content: space-between;
   }
   &__row:nth-child(2) {
      align-items: flex-end;
   }
   &__quantity {
      display: flex;
      flex-direction: column;
      gap: 5px;
   }
   &__qty-modificator {
      width: 6;
      height: 20px;
      width: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: var(--white-s);
      color: var(--black-s);
      border-radius: 20px;
      font-weight: 800;
      line-height: 1;
      transition: 0.3s;
      cursor: pointer;
   }
   &__qty-modificator:hover {
      background: var(--white-p);
   }
}
.cart-products-item.hide {
   opacity: 0;
   transform: scale(0.9);
}

@media (max-width: $mobile) {
   .cart-products {
      &__grid {
         grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      }
      &__more-1,
      &__more-2 {
         font-size: 50px;
         aspect-ratio: 2/1;
      }
      &__more:hover {
         font-size: 60px;
         color: var(--white-p);
      }
   }
}
@media (max-width: 440px) {
   .cart-products-item {
      &__trash-icon {
         width: 50px;
         height: 25px;
         cursor: pointer;
         div svg {
            height: 12px;
            width: 12px;
         }
      }
   }
}

@media (max-width: 360px) {
   .cart-products-item {
      &__info {
         padding: 2px;
         position: relative;
         aspect-ratio: none;
      }
      &__text,
      &__name {
         font-size: 14px;
         text-align: center;
      }
   }
}
