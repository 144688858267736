.error {
   display: flex;
   flex: 1 1 auto;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   min-height: calc(100svh - 170px);
   gap: 10px;
   &__title {
      font-size: 200px;
   }
   &__text {
      font-size: 20px;
      color: var(--white-s);
   }
}
@media (max-width: $tablet) {
   .error {
      &__title {
         font-size: 150px;
      }
      &__text {
         font-size: 16px;
      }
   }
}
@media (max-width: $mobile) {
   .error {
      &__title {
         font-size: 100px;
      }
      &__text {
         font-size: 14px;
      }
   }
}
@media (max-width: $mobileSmall) {
   .error {
      &__title {
         font-size: 70px;
      }
      &__text {
         font-size: 14px;
      }
   }
}
