.intro {
   &__body {
      display: flex;
      flex-direction: column;
      padding: 10px;
      border-radius: 25px;
      border: 1px solid var(--border);
      aspect-ratio: 1/1;
      position: relative;
      overflow: hidden;
      aspect-ratio: 3/1;
   }
   &__info {
      padding: 15px;
      border-radius: 15px;
      background: #19181580;
      backdrop-filter: blur(10px);
      width: 60%;
   }
   &__img {
      position: absolute;
      top: 0%;
      right: 0;
      width: 100%;
      z-index: -1;
      height: 100%;
      object-fit: cover;
      transition: 0.3s;
      filter: brightness(40%) hue-rotate(20deg);
   }
   &__more-wrapper {
      position: absolute;
      bottom: 10px;
      right: 10px;
      padding: 10px 10px 10px 20px;
      display: flex;
      gap: 10px;
      align-items: center;
      background: #19181580;
      backdrop-filter: blur(10px);
      border-radius: 25px;
      color: var(--white-s);
      p {
         text-transform: capitalize;
         font-size: 16px;
         font-weight: 600;
         text-shadow: 0 0 5px #46464600, 0 0 10px #46464600;
      }
   }
   &__link {
      z-index: 3;
      color: #111;
      background: #fff;
      border-radius: 50px;
      width: 60px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
   }
   &__svg {
      div {
         display: flex;
         justify-content: center;
         align-items: center;
         svg {
            height: 14px;
            width: 14px;
            transition: 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
            transform: rotate(135deg);
         }
      }
   }
   p,
   h1 {
      z-index: 5;
      color: #fff;
   }
}
@media (max-width: $tablet) {
   .intro {
      &__info {
         width: 100%;
      }
   }
}
@media (max-width: $mobile) {
   .intro {
      &__body {
         aspect-ratio: 1/1;
      }
      &__text {
         width: 100%;
      }
      &__link {
         aspect-ratio: none;
      }
   }
}
