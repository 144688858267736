.last-products {
   width: 100%;
   padding: 15px 15px 0 15px;
   &__body {
      padding: 10px 10px 10px 20px;
      border-radius: 25px;
      background: var(--black-s);
      border: 1px solid var(--border);
      display: flex;
      position: relative;
      height: 50px;
   }
   &__partners {
      flex: 1 0 auto;
      mask-image: linear-gradient(
         to left,
         rgba(0, 0, 0, 0),
         #000,
         #000,
         rgba(0, 0, 0, 0)
      );
      position: relative;
      overflow: hidden;
   }
   &__partner {
      font-size: 20px;
      font-weight: 700;
      text-transform: uppercase;
      position: absolute;
      white-space: nowrap;
      animation: scroll-left 30s ease-in-out infinite;
      color: var(--white-p);
      transition: 1s;
   }
   &__body:hover &__partner {
      transform: translate(50px);
   }
}

@keyframes scroll-left {
   0% {
      left: 0%;
      width: 100%;
   }
   50% {
      left: -100%;
      width: 100%;
   }
   100% {
      left: 0%;
      width: 100%;
   }
}
