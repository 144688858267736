.modal {
   position: fixed;
   left: 0;
   top: 0;
   z-index: 1000;
   overflow: auto;
   background: #101010c3;
   width: 100vw;
   height: 100vh;
   pointer-events: all;
   cursor: pointer;
   opacity: 1;
   &__close {
      position: absolute;
      top: 40px;
      right: 40px;
      color: #fff;
      pointer-events: all;
      transition: 0.5s;
      z-index: 100;
   }
   &__content {
      user-select: none;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100svw;
      height: 100svh;
      margin: auto;
      > img {
         cursor: auto;
         height: 90%;
         width: auto;
         border-radius: 25px;
         border: 1px solid var(--bd-color);
         transform: scale(0.9);
         transition: 0.5s;
         @media (max-width: $tablet) {
            height: 70%;
            width: auto;
         }
         @media (max-width: $mobile) {
            height: auto;
            width: 90%;
         }
      }
   }
}

.modal.open {
   opacity: 1;
   animation: openModal 0.3s forwards;
}
@keyframes openModal {
   from {
      opacity: 0;
   }
   to {
      opacity: 1;
   }
}
.modal.close {
   opacity: 0;
   animation: closeModal 0.3s forwards;
}
@keyframes closeModal {
   from {
      opacity: 1;
   }
   to {
      opacity: 0;
   }
}
