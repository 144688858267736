.types {
   &__body {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 20px;
   }
   &__item {
      display: flex;
      flex-direction: column;
      gap: 20px;
      padding: 25px;
      border-radius: 25px;
      border: 1px solid var(--border);
      aspect-ratio: 1/1;
      position: relative;
      background: #111;
      box-shadow: 0 2px 0 var(--black-p);
      margin-bottom: 10px;

      &::after {
         content: '';
         position: absolute;
         bottom: -9px;
         right: 0;
         width: 100%;
         height: 100%;
         border-radius: 25px;
         background: #444;
         z-index: -1;
         transform-origin: bottom;
         transform: scale(0.93);
         box-shadow: 0 2px 0 var(--black-p);
      }
      &::before {
         content: '';
         position: absolute;
         bottom: -16px;
         right: 0;
         width: 100%;
         height: 100%;
         border-radius: 25px;
         background: #222;
         z-index: -1;
         transform-origin: bottom;
         transform: scale(0.88);
      }
   }
   &__title {
      text-shadow: 0 0 10px #111;
      z-index: 5;
      color: #fff;
   }
   &__link {
      z-index: 3;
      color: #111;
      background: #fff;
      position: absolute;
      border-radius: 50px;
      right: 20px;
      top: 20px;
      width: 60px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px;
   }
   &__svg {
      div {
         display: flex;
         justify-content: center;
         align-items: center;
         svg {
            height: 14px;
            width: 14px;
            transition: 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
         }
      }
   }
   &__item:hover &__svg svg {
      transform: rotate(45deg);
   }
   &__img {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      object-fit: cover;
      border-radius: 23px;
      border: 1px solid var(--border);
      mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), #000);
      transition: 0.3s;
   }
   &__colab {
      z-index: 3;
      color: #111;
      background: #fff;
      position: absolute;
      border-radius: 50px;
      right: 20px;
      bottom: 20px;
      padding: 8px 24px;
      font-weight: 700;
   }
}

@media (max-width: $mobile) {
   .types {
      &__body {
         display: grid;
         grid-template-columns: 1fr;
         gap: 20px;
      }
   }
}
