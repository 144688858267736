.products {
   &__grid {
      display: grid;
      grid-template-rows: repeat(1fr);
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      gap: 20px;
   }
}
.product-item {
   display: grid;
   grid-template-rows: 1fr;
   width: 100%;
   gap: 20px;
   position: relative;
   aspect-ratio: 1 / 1;
   border-radius: 25px;
   overflow: hidden;
   border: 1px solid var(--border);
   user-select: none;
   cursor: pointer;
   &__select-color,
   &__select-size,
   &__add-to-card,
   &__open-icon,
   &__more-icon {
      color: #050505;
      background: #fff;
      position: absolute;
      border-radius: 50px;
      top: 20px;
      width: 60px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px;
      z-index: 50;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 10px -2px,
         rgba(0, 0, 0, 0.3) 0px 9px 18px -9px;
      cursor: pointer;
   }
   &__open-icon {
      left: 20px;
      pointer-events: none;
   }
   &__svg {
      div {
         display: flex;
         justify-content: center;
         align-items: center;
         svg {
            height: 14px;
            width: 14px;
            transition: 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
         }
      }
   }
   &:hover &__open-icon &__svg svg {
      transform: rotate(45deg);
   }
   &__more-icon {
      right: 20px;
      z-index: 100;
      svg {
         position: absolute;
      }
   }
   &__svg-dots {
      opacity: 1;
      visibility: visible;
      transition: 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);

      div svg {
         width: 22px;
      }
   }
   &__svg-cross {
      opacity: 0;
      visibility: hidden;
      transition: 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
   }
   &__add-to-card {
      right: 20px;
      top: 20px;
      opacity: 0;
      visibility: hidden;
      transition: 0.2s;
      div svg {
         width: 24px;
      }
   }
   &__select-size {
      cursor: pointer;
      top: 20px;
      right: 20px;
      overflow: hidden;
      opacity: 0;
      visibility: hidden;
      transition: 0.2s;
      select {
         padding: 5px 0 5px 5px;
         font-weight: 600;
         cursor: pointer;
         background: #fff;
         color: #050505;
      }
   }
   &__select-color {
      top: 20px;
      right: 20px;
      overflow: hidden;
      font-weight: 600;
      font-size: 10px;
      opacity: 0;
      visibility: hidden;
      transition: 0.2s;
      select {
         padding: 5px 0 5px 5px;
         font-weight: 600;
         cursor: pointer;
         background: #fff;
         color: #050505;
      }
   }
   &__option {
      font-weight: 600;
      text-transform: capitalize;
   }
   &__placeholder {
      width: 100%;
   }
   &__text {
      text-transform: capitalize;
   }
   &__info {
      position: absolute;
      bottom: 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      gap: 10px;
      padding: 20px 20px 20px 20px;
      opacity: 0;
      pointer-events: none;
      z-index: 10;
      height: 100%;
      width: 100%;
      transition: 0.5s cubic-bezier(0.175, 0.885, 0.32, 1);
   }
   &__details {
      position: absolute;
      left: 20px;
      bottom: 20px;
      right: 20px;
      padding: 5px 15px;
      border-radius: 20px;
      display: flex;
      justify-content: space-between;
      background: var(--black-s);
      border: 1px solid var(--border);
      z-index: 100;
   }
   &__name {
      white-space: nowrap;
      width: 100%;
      mask-image: linear-gradient(to left, rgba(0, 0, 0, 0.5), #000);
      overflow: hidden;
   }
   &__isNew {
      position: absolute;
      bottom: 26px;
      right: -5px;
      font-weight: 600;
      font-size: 10px;
      background: #ff4545;
      color: #050505;
      padding: 3px 9px;
      border-radius: 20px;
   }
   &__img {
      width: 100%;
      position: absolute;
      object-fit: cover;
   }
   .lazy-load-image-background.blur.lazy-load-image-loaded {
      position: relative;
      width: 100%;
      height: 100%;
      display: block !important;
   }
   .lazy-load-image-background.blur.lazy-load-image-loaded > img {
      opacity: 1;
      transform: translateY(0%) scale(1);
      z-index: 10;
      transition: 0.6s cubic-bezier(0.175, 0.885, 0.32, 1);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
   }
}
.product-item.more {
   .product-item__svg-dots {
      opacity: 0;
      visibility: hidden;
      transition: 0.5s cubic-bezier(0.175, 0.885, 0.32, 1);
   }
   .product-item__svg-cross {
      opacity: 1;
      visibility: visible;
   }
   .product-item__add-to-card {
      top: 60px;
      opacity: 1;
      visibility: visible;
   }
   .product-item__select-size {
      opacity: 1;
      right: 90px;
      visibility: visible;
   }
   .product-item__select-color {
      right: 90px;
      top: 60px;
      opacity: 1;
      visibility: visible;
   }
   .lazy-load-image-background.blur.lazy-load-image-loaded > img {
      opacity: 0.1;
      transform: translateY(-10%) scale(1.2);
   }
   .product-item__info {
      padding: 20px 20px 70px 20px;
      opacity: 1;
      pointer-events: all;
      transition: padding-bottom 0.5s cubic-bezier(0.175, 0.885, 0.32, 1);
   }
}
// on page our products =========================================
.what-products__svg {
   div {
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
         height: 14px;
         width: 14px;
         transition: 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
         transform: rotate(135deg);
      }
   }
}

// swiper =========================================
.swiper {
   width: 100%;
   height: 100%;
}
.swiper-slide {
   overflow: hidden !important;
}
.swiper-slide img {
   width: 100%;
   height: 100%;
}
.swiper-scrollbar {
   left: 25px !important;
   right: 25px !important;
   width: calc(100% - 50px) !important;
   bottom: 68px !important;
   outline: 4px solid var(--white-t);
   background: var(--white-t) !important;
   height: 6px !important;
   transition: 0.3s;
   &-drag {
      background: var(--black-p) !important;
   }
}
.product-item.more .swiper-scrollbar {
   opacity: 0;
   bottom: 44px !important;
}

// button animation on add in cart =========================================
.animation {
   position: absolute;
   bottom: 0;
   left: 100%;
   transform: translateX(-50%);
   color: #000000;
   font-weight: 800;
   background: rgb(0, 255, 0);
   animation: fly-up 1s linear;
   border-radius: 30px;
   padding: 4px 8px;
   border-radius: 20px;
   font-size: 10px;
   line-height: 0.7;
}
@keyframes fly-up {
   0% {
      opacity: 0;
      transform: translate(-100%, 0);
   }
   20% {
      opacity: 1;
   }
   70% {
      opacity: 0;
   }
   100% {
      opacity: 0;
      transform: translate(-100%, -70px);
   }
}
