.cart-payment {
   &__body {
      display: grid;
      gap: 15px;
   }
}

.cart-form,
.cart-form-totals {
   border-radius: 25px;
   border: 1px solid var(--border);
   background: var(--black-s);
}

.cart-form {
   padding: 20px;
   &__title {
      padding-bottom: 20px;
      font-size: 25px;
   }
   &__form {
      display: flex;
      flex-direction: column;
      gap: 10px;
   }
   &__content {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;
   }
   &__inputs {
      display: flex;
      flex-direction: column;
      gap: 10px;
      ::-webkit-input-placeholder {
         font-weight: 300;
      }
   }
   &__row {
      display: grid;
      grid-template-columns: 0.7fr 1.3fr;
      grid-template-rows: auto-fit;
   }
   &__lable {
      white-space: nowrap;
   }
   &__select,
   &__input {
      color: var(--white-p);
      background: var(--black-p);
      border: 1px solid var(--border);
      border-radius: 24px;
      padding: 6px 21px;
   }
   &__final-totals {
      color: var(--white-p);
      background: var(--black-s);
      border: 1px solid var(--border);
      border-radius: 12px;
   }
   &__row-totals {
      display: flex;
      justify-content: space-between;
      padding: 10px 20px;
      gap: 10px;
      border-bottom: 1px solid var(--border);
      sup {
         padding: 2px 8px 4px 8px;
         border-radius: 20px;
         font-weight: 500;
         font-size: 10px;
         line-height: 0;
         color: var(--white-p);
      }
      sup.green {
         background: var(--green);
         text-decoration: none;
      }
      sup.red {
         background: var(--red);
         text-decoration: line-through;
      }
   }
   &__row-totals:last-child {
      border-bottom: 1px solid transparent;
   }
   button {
      margin-top: 5px;
      padding: 5px 15px;
      background: var(--white-p);
      color: var(--black-p);
      align-self: center;
      border-radius: 25px;
   }
   .error-message {
      color: var(--red);
      font-size: 12px;
      margin-top: -10px;
      margin-left: 38%;
   }
}

.cart-form-totals {
   &__title {
      padding: 20px;
      font-size: 25px;
   }
   &__grid {
      display: flex;
      flex-direction: column;
      border-radius: 15px;
   }
   &__item {
      display: flex;
      justify-content: space-between;
      padding: 10px 20px;
      gap: 10px;
      border-bottom: 1px solid var(--border);
   }
   &__item:nth-child(even) {
      background-color: var(--black-p);
   }
   &__price {
      text-align: right;
   }
   &__totals {
      display: flex;
      justify-content: space-between;
      font-weight: 600;
      padding: 10px 20px 20px 20px;
   }
}

.order-popup {
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100svh;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   gap: 10px;
   background: #1f1f1fed;
   z-index: 9999;
   opacity: 1;

   &__icon {
      background: var(--green);
      padding: 5px;
      aspect-ratio: 1/1;
      border-radius: 50%;
      div {
         display: flex;
         justify-content: center;
         align-items: center;
      }
      svg {
         height: 80px;
         width: 80px;
         color: var(--white-p);
      }
   }
   &__text {
      font-weight: 600;
      letter-spacing: 1.3px;
      color: #fff;
      text-align: center;
   }
   &__sub-text {
      color: #bfbfbf;
      text-align: center;
   }
   &.loading {
      animation: order-popup-in 0.2s forwards;
   }
   &.error {
      .order-popup__icon {
         background: var(--red);
      }
      .order-popup__icon svg {
         animation: order-popup-in 0.4s forwards;
      }
   }
   &.succes {
      .order-popup__icon svg {
         animation: order-popup-in 0.4s forwards;
      }
   }
}
@keyframes order-popup-in {
   from {
      opacity: 0;
   }
   to {
      opacity: 1;
   }
}

@media (max-width: $mobile) {
   .cart-payment {
      &__body {
         grid-template-columns: 1fr;
      }
   }

   .cart-form-totals {
      &__more {
         font-size: 50px;
      }
      &__body:hover &__more {
         font-size: 60px;
         color: var(--white-p);
      }
      &__text {
         bottom: 20px;
         left: 50%;
      }
   }
   .cart-form {
      padding: 20px;
      &__form {
         gap: 15px;
      }
      &__content {
         grid-template-columns: 1fr;
         gap: 15px;
      }
      &__inputs {
         display: flex;
         flex-direction: column;
         gap: 10px;
      }
      &__row {
         display: grid;
         grid-template-rows: 1fr 1fr;
         grid-template-columns: 1fr;
         gap: 0;
      }
      button {
         width: 100%;
      }
      .error-message {
         color: var(--red);
         font-size: 14px;
         margin-top: -10px;
         margin-left: 0%;
      }
   }
}
