.follow {
   &__body {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 15px;
   }
   &__item {
      display: flex;
      align-items: center;
      padding: 10px;
      background: var(--black-s);
      gap: 15px;
      border: 1px solid var(--border);
      border-radius: 30px;
      color: var(--white-p);
      transition: 0.3s;
   }
   &__link {
      padding: 10px;
      border-radius: 50%;
      background: #19181580;
   }
   &__svg {
      color: #fff;
      div {
         width: 20px;
         height: 20px;
      }
      svg {
         width: 20px;
         height: 20px;
      }
   }
   &__name {
      font-style: 30px;
      font-weight: 600;
      text-align: center;
      color: #fff;
   }
}
.follow__item {
   &:nth-child(1) {
      background: radial-gradient(
         circle at 30% 110%,
         #ffdb8b 0%,
         #ee653d 25%,
         #d42e81 50%,
         #a237b6 75%,
         #3e5fbc 100%
      );
   }
   &:nth-child(2) {
      //background: conic-gradient(
      //   #4285f4,
      //   #4285f4,
      //   #db4437,
      //   #db4437,
      //   #f4b400,
      //   #f4b400,
      //   #0f9d58,
      //   #0f9d58,
      //   #4285f4
      //);
      background: linear-gradient(45deg, #ee1d52, #69c9d0);
   }
   &:nth-child(3) {
      background: #4267b2;
   }
}
.follow__item:hover {
   opacity: 0.7;
}
@media (max-width: $tablet) {
   .follow {
      &__body {
         grid-template-columns: 1fr;
      }
      &__name {
         width: calc(100% - 100px);
         text-align: center;
      }
   }
}
