.footer {
   padding: 15px;
   &__body {
      padding: 10px 20px;
      background: var(--black-s);
      border: 1px solid var(--border);
      border-radius: 25px;
      width: 100%;
      text-align: center;
   }
   @media (max-width: $mobile) {
      padding: 15px 15px 70px 15px;
   }
}
