.header {
   width: 100%;
   position: fixed;
   top: 0;
   left: 0;
   user-select: none;
   z-index: 200;
   padding: 10px;
   &__body {
      display: flex;
      justify-content: space-between;
      gap: 30px;
      position: relative;
      padding: 10px 12px 10px 15px;
      background: var(--black-s);
      border: 1px solid var(--border);
      border-radius: 25px;
   }
   &__left {
      display: flex;
      gap: 10px;
   }
   &__logo {
      z-index: 5;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 26px;
      color: var(--white-p);
      div {
         height: 26px;
      }
      svg {
         height: 26px;
      }
   }
}
.menu {
   display: flex;
   align-items: center;
   gap: 10px;
   &__list {
      display: flex;
      gap: 5px;
      z-index: 5;
   }
   &__cart,
   &__item {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid transparent;
      border-radius: 15px;
      transition: 0.2s;
      cursor: pointer;
   }
   &__cart:hover,
   &__item:hover {
      border: 1px solid var(--border);
   }
   &__cart,
   &__item a {
      color: var(--white-p);
      padding: 3px 14px;
      font-size: 14px;
   }
   &__cart {
      position: relative;
      span {
         position: absolute;
         top: -6px;
         right: -10px;
         padding: 4px 8px;
         background: var(--white-p);
         color: var(--black-p);
         border-radius: 20px;
         font-size: 10px;
         line-height: 0.7;
      }
   }
}
.settings {
   z-index: 5;
   &__mode-btn {
      position: relative;
      height: 26px;
      width: 52px;
      display: flex;
      align-items: center;
      justify-content: center;
   }
   &__icon {
      color: var(--white-p);
      position: absolute;
      transition: 0.2s;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 26px;
      width: 52px;
      div {
         height: 26px;
         width: 52px;
      }
      svg {
         height: 26px;
         width: 52px;
      }
   }
   // change icon on click
   &__icon:nth-child(1) {
      opacity: 1;
   }
   &__icon:nth-child(2) {
      opacity: 0;
   }
   .darkmode &__icon:nth-child(1) {
      opacity: 0;
   }
   .darkmode &__icon:nth-child(2) {
      opacity: 1;
   }
}

@media (max-width: $mobile) {
   .menu {
      width: 100%;
      padding: 10px;
      position: fixed;
      bottom: 0;
      right: 0;
      &__list {
         width: 100%;
         display: flex;
         justify-content: space-evenly;
         align-items: center;
         border: 1px solid var(--border);
         border-radius: 25px;
         background: var(--black-s);
         padding: 10px;
      }
   }
}

