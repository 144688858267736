// import fonts/font <=>
@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100;300;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Glass+Antiqua&family=Playfair+Display:ital,wght@0,400;0,500;1,400;1,500&display=swap');
// default font / default font size <=>
$fontFamily: 'Lexend', sans-serif;
$fontSize: 14;
// transition for dark mode and light
* {
   transition: color 0.2s;
   transition: background 0.2s;
}
// colors palette

:root {
   --black-p: #0e0e0c;
   --black-s: hsl(60, 7%, 7%);
   --black-t: #19181580;
   --white-p: #fffff2;
   --white-s: #dadada;
   --white-t: #fffffd3b;
   --border: #222222;
   --red: #7a1e1e;
   --green: #276a1a;
}

.darkmode {
   --black-p: #f2f2f2;
   --black-s: hsl(0, 100%, 100%);
   --black-t: #fffffd80;
   --white-p: #1a1a16;
   --white-s: #191815;
   --white-t: #19181554;
   --border: #aeaeae;
   --red: #ff4545;
   --green: #64ff45;
}
// max width for container <=>
$maxWidthContainer: 1250;
// size gadjes for adapted and other <=>
$tablet: 991.98px;
$mobile: 767.98px;
$mobileSmall: 479.98px;
// null style <=>
@import 'scss/_null.scss';
// general style <=>
body {
   line-height: 1.4;
   font-family: $fontFamily;
   font-size: $fontSize + px;
   background: var(--black-p);
   animation: mainanim 0.7s ease-in-out;
   color: var(--white-s);
   font-weight: 300;
   position: relative;
}
@keyframes mainanim {
   from {
      opacity: 0;
   }
   to {
      opacity: 1;
   }
}
// wrapper <=>
#root,
.App,
body {
   min-height: 100%;
   display: flex;
   flex-direction: column;
   .main {
      padding-top: 60px;
      flex: 1 1 auto;
   }
}
// container <=>
.container {
   max-width: $maxWidthContainer + px;
   margin: 0 auto;
   //fix for slider
   > * {
      min-width: 0;
   }
}

// scroll bar <=>
@media only screen and (max-width: $mobile) {
   ::-webkit-scrollbar {
      display: none;
   }
}
::-webkit-scrollbar {
   background-color: var(--black-p);
   width: 16px;
}
::-webkit-scrollbar-track {
   background-color: var(--black-p);
}
::-webkit-scrollbar-thumb {
   background-color: var(--white-p);
   border-radius: 16px;
   border: 4px solid var(--black-p);
}
::-webkit-scrollbar-thumb:hover {
   background-color: var(--white-s);
}
::-webkit-scrollbar-thumb:active {
   background-color: var(--white-p);
}
::-webkit-scrollbar-button {
   display: none;
}

// when copu text<=>
::selection {
   color: var(--black-p);
   background: var(--white-p);
}

// imports
@import './scss/_utils';
@import './scss/_header';
@import './scss/_404';
@import './scss/_footer';
@import './scss/_intro';
@import './scss/_about';
@import './scss/_partners';
@import './scss/_types';
@import './scss/_products';
@import './scss/_modal';
@import './scss/_cartModal';
@import './scss/_cartForm';
@import './scss/_promocode';
@import './scss/_follow';
@import './scss/_purpose';
@import './scss/_lastProducts';
