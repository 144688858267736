// title
.title {
   font-weight: 600;
   font-size: 40px;
   line-height: 1;
   color: var(--white-p);
   padding-bottom: 10px;
   text-transform: uppercase;
   @media (max-width: 1150px) {
      font-size: 30px;
   }
   @media (max-width: $mobileSmall) {
      font-size: 25px;
   }
}
//text
.text {
   font-size: 18px;
   @media (max-width: 1300px) {
      font-size: 16px;
      padding: 0px;
   }
}
//section
.section {
   width: 100%;
   padding: 15px 15px 0 15px;
   &__body {
      width: 100%;
   }
}

